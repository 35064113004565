import { twMerge } from 'tailwind-merge'
import { useEffect, useState } from 'react'
import { useSearchParams, useRouter } from 'next/navigation'

import { useStore } from 'lib/store'
import { Button, Icon, Box } from 'components'
import { InputLocation } from './input-location'

export const BoxSearch = () => {
  const router = useRouter()
  const searchParams = useSearchParams()
  const [searchTitle, setSearchTitle] = useState<string>('')
  const [searchLocation, setSearchLocation] = useState<string>('')
  const { company, updateStoreFilter } = useStore(store => store)

  const handleSearch = (title: string) => {
    const query = updateStoreFilter({
      title,
      locations: searchLocation,
      page: 1
    })
    router.push('/?' + query)
  }

  useEffect(() => {
    const initParams = {
      page: Number(searchParams.get('page')) ?? 1,
      title: searchParams.get('title') ?? '',
      locations: searchParams.get('locations') ?? ''
    }

    if (initParams.page > 1 || initParams.title || initParams.locations) {
      initParams.title && setSearchTitle(initParams.title)
      initParams.locations && setSearchLocation(initParams.locations)
      updateStoreFilter(initParams)
    }
  }, [])

  const handleClearSearchTitle = () => {
    setSearchTitle('')
    handleSearch('')
  }

  return (
    <Box className="w-full flex flex-col md:flex-row gap-4 items-end p-4 md:p-6">
      <InputLocation value={searchLocation} onChange={setSearchLocation} />

      <fieldset className="flex flex-col gap-[5px] w-full items-start">
        <label
          htmlFor="search-vacancy-name"
          className="text-body2 font-regular"
        >
          Qual vaga você procura?
        </label>
        <div className="w-full flex items-center gap-3 bg-transparent h-12 px-4 rounded-lg border border-gray-500/[.32] hover:border-gray-800">
          <Icon face="search" size="md" className="text-gray-500" />
          <input
            type="text"
            maxLength={75}
            value={searchTitle}
            placeholder="Nome da vaga ou cargo"
            onChange={event => setSearchTitle(event.target.value)}
            className="placeholder:text-font-color-disabled text-body1 text-gray-800 outline-none flex-1"
          />

          {searchTitle && (
            <button
              type="button"
              onClick={handleClearSearchTitle}
              className="w-6 h-6 flex rounded-full bg-transparent hover:bg-gray-800/10 transition-all duration-300"
            >
              <Icon face="clear" className="m-auto text-gray-700" />
            </button>
          )}
        </div>
      </fieldset>

      <Button
        type="button"
        onClick={() => handleSearch(searchTitle)}
        className={twMerge(
          'min-w-full md:min-w-[25%] h-12 items-center',
          company.color.background
        )}
      >
        Buscar vagas
      </Button>
    </Box>
  )
}
