import { useState } from 'react'
import { Button, Icon, ModalAction } from 'components'

import env from 'lib/env'
import { useStore } from 'lib/store'
import { useToast } from 'hooks/useToast'
import { handleToAuth } from 'services/user'
import { cookies, redirect } from 'lib/cookies'
import { applyTalentBankInCompany } from 'services/company'

export const ButtonApplyTalentBank = () => {
  const toast = useToast()
  const { company, user, isLoadingUser, updateStoreUser } = useStore()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [modal, setModal] = useState<boolean>(false)

  const setCookiesApplyTalentBank = () => {
    const tabKey = cookies.get('redirectCurrentWindow') || ''
    const tab = JSON.parse(cookies.get(tabKey) || '{}')
    const payload = {
      ...tab,
      company: {
        referenceId: company.referenceId,
        slug: company.slug,
        name: company.name
      },
      applyingTalentBase: true,
      origin: ''
    }

    cookies.set(window.name, JSON.stringify(payload))
    cookies.set(
      'applying-talent-base',
      JSON.stringify({
        applyingTalentBase: true,
        applied: false,
        slug: company.slug,
        referenceId: company.referenceId
      })
    )
  }

  const onRefreshTalentBank = async () => {
    setCookiesApplyTalentBank()
    return redirect(env.host_profile.concat('/curriculo'))
  }

  const onApplyTalentBank = async () => {
    setIsLoading(true)
    const isSuccess = await applyTalentBankInCompany(company)
    if (!isSuccess) {
      toast.error({ title: 'Falha ao aplicar ao banco de talentos!' })
    }
    setIsLoading(false)
    setModal(false)
    updateStoreUser({ appliedTalentBank: true })
  }

  const handleApply = () => {
    if (!user) {
      handleToAuth()
      return
    }

    setModal(true)
  }

  return (
    <>
      <ModalAction
        icon="edit"
        open={modal}
        iconVariant="round"
        title="Gostaria de revisar o seu currículo?"
        description="Ao enviar o seu currículo, seu cadastro será realizado imediatamente"
        cancelButton={{
          action: onRefreshTalentBank,
          label: 'Revisar'
        }}
        confirmButton={{
          action: onApplyTalentBank,
          label: 'Enviar',
          loading: isLoading
        }}
      />

      {user?.appliedTalentBank ? (
        <div className="flex items-center justify-center gap-1.5 rounded-lg px-6 mt-6 bg-white/10 h-10 text-white">
          <Icon face="task_alt" />
          <span className="text-body2">Seu currículo já está cadastrado!</span>
        </div>
      ) : (
        <Button
          onClick={handleApply}
          loading={isLoadingUser}
          colorCompany
          className="mt-6 py-2.5"
        >
          Cadastre seu currículo
        </Button>
      )}
    </>
  )
}
