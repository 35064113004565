import Image from 'next/image'
import { Icon } from 'components'
import { useStore } from 'lib/store'
import { twMerge } from 'tailwind-merge'
import { Testimonial } from 'types/company'
import { ChangeEvent, useState } from 'react'

export const TestimonialCard = (testimonial: Testimonial) => {
  const btnColor = useStore(store => store.company.color.background)
  const [isErrorAvatar, setIsErrorAvatar] = useState<boolean>(false)

  const onErrorLoadAvatar = (e: ChangeEvent<HTMLImageElement>) => {
    e.stopPropagation()
    setIsErrorAvatar(true)
  }

  return (
    <article className="h-full w-full py-5 md:py-6 px-4 md:px-8 bg-white rounded-xl shadow-sm shadow-gray-400">
      <div className="flex items-center gap-x-3">
        <div className="w-12 h-12 rounded-full relative overflow-hidden">
          {testimonial?.url && !isErrorAvatar ? (
            <Image
              src={testimonial.url}
              loading="lazy"
              fill
              className="object-cover"
              alt={`Imagem de avatar do usuário ${testimonial?.name}`}
              sizes="48px"
              onError={onErrorLoadAvatar}
            />
          ) : (
            // eslint-disable-next-line prettier/prettier
            <div className={twMerge('h-full flex items-center justify-center', btnColor)}>
              <Icon face="person_outline" size="md" className="text-white" />
            </div>
          )}
        </div>

        <div className="flex-1 flex flex-col">
          <strong className="text-body2 md:text-xs text-gray-800">
            {testimonial.name}
          </strong>
          {testimonial.role && (
            <span className="text-gray-600 font-medium text-body2 -mt-1">
              {testimonial.role}
            </span>
          )}
        </div>
      </div>

      <span
        className="block text-body2 text-gray-800 mt-5"
        dangerouslySetInnerHTML={{ __html: testimonial?.text }}
      />
    </article>
  )
}
