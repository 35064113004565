import api from 'lib/api'

import { Filter } from 'lib/store'

interface DataVacancy {
  count: number
  currentPage: number
  data: any[]
  totalPages: number
}

interface VacanciesResponse {
  data: DataVacancy
}

export const getVacancies = async (
  filter: Filter,
  slug: string
): Promise<DataVacancy> => {
  try {
    const params = { take: 12, slug, ...filter }

    const response = await api<VacanciesResponse>('/home/vacancy', { params })

    if (response.data.data?.data) {
      return response.data.data
    }
  } catch (err) {
    console.log(err)
  }

  return { count: 0, currentPage: 0, data: [], totalPages: 0 }
}

const applicantsLegacy = async (): Promise<number[]> => {
  try {
    const route = '/applicant/my-applications'
    const response = await api<{ data: { id: number }[] }>(route, {}, 'v2')

    return response.data.data.map(vacancy => vacancy.id)
  } catch (err) {
    console.log(err)
  }

  return []
}

export const getVacancyApplied = async (
  vacancyId: number
): Promise<boolean> => {
  const vacancies = await applicantsLegacy()

  return vacancies.includes(vacancyId)

  // try {
  //   const route = '/vacancy-applied/' + vacancyId
  //   const response = await api<{ data: boolean }>(route)
  //   return response.data.data
  // } catch (err) {
  //   console.error(err)
  // }

  // return false
}

export const getCitiesInVacancies = async (slug: string): Promise<string[]> => {
  try {
    const params = { slug }
    const response = await api<{ name: string }[]>('/home/cities', { params })

    const cities = response.data.map(item => item.name)

    return ['Todos', ...cities]
  } catch (err) {
    console.log(err)
  }

  return []
}
