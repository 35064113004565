import { useEffect } from 'react'

import { SEO } from 'components'
import { useStore } from 'lib/store'
import { getCompany } from 'services/company'
import {
  BoxSearch,
  ListVacancies,
  BannerTalentBank,
  Company
} from 'components/Homepage'

const Homepage = () => {
  const { company, updateStoreCompany } = useStore()
  const loadMoreInfoCompany = async () => {
    if (!company?.description) {
      const moreInfo = await getCompany(company.slug)
      updateStoreCompany(moreInfo)
    }
  }

  useEffect(() => {
    company.slug && loadMoreInfoCompany()
  }, [company.slug])

  return (
    <>
      <SEO
        tabName={`Vagas de Emprego na ${company.name}`}
        title={`Vagas de Emprego na ${company.name} na Lista de vagas`}
        keywords={`vagas de emprego, empregos, vagas na ${company.name}, trabalho na ${company.name}, carreiras, oportunidades, oportunidades de trabalho, recrutamento`}
        description={`Busque vagas de emprego na ${company.name}. Encontre as melhores oportunidades e inicie sua carreira em uma empresa inovadora e de referência no mercado. Aproveite essa chance e faça parte do nosso time!`}
      />

      <div className="mt-32 md:mt-52 w-full max-w-7xl self-center flex flex-col gap-10 md:gap-16 px-4 xl:px-0">
        <div className="flex flex-col gap-4">
          <BoxSearch />
          <ListVacancies />
        </div>
        <Company />
      </div>

      <BannerTalentBank />
    </>
  )
}

export default Homepage
